<template>

  <div class="container">
    <div class="row">
      <div class="col-12 ">
                <div class="users">

                  <p style="text-align: center; font-size: 30px">Manage users</p>
                  <table class="table">
                    <tr>
                    <th>User's name</th>
                    <th>User's email</th>
                    <th>Last active</th>
                    <th>Active/Freeze</th>
                    <th>Role</th>
                    <th>Make admin/user</th>
                    <th>Reset password</th>
                    </tr>

                    <tr v-for="user in all_users" v-bind:key="user._id">
                      <td>{{ user.name }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ getDate(user.last_active_time) }}</td>
                      <td><a href="#" @click="toggle_freeze(user)">{{ user.is_active ? 'Freeze?' : 'Unfreeze?' }}</a></td>
                      <td>{{ user.role }}</td>
                      <td><a href="#" @click="toggle_make_admin(user)">{{ user.role === 'admin' ? 'Make User' : 'Make Admin' }}</a></td>
                      <td> <a href="#" @click="reset_password(user)">Reset </a> </td>

                    </tr>
                  </table>

                  </div>
        </div>
      </div>

    <div class="row">
      <div class="col-12 ">
                <div class="users">

                  <p style="text-align: center; font-size: 30px">Create User</p>


                  <div class="form-group">
                    <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Enter Name" v-model="name">

                  </div>
                  <div class="form-group mb-4">
                    <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter email" v-model="email">
                  </div>


                  <div class="form-group clearfix">
                    <button type="submit" class="btn btn-purple btn-pill float-right" @click="create_user">Create</button>
                  </div>
                  </div>
        </div>
      </div>


     <div class="row">
      <div class="col-12 ">

        <div class="users">

          <p style="text-align: center; font-size: 30px">Manage users</p>
          <table class="table">
            <tr>
              <th>Color name</th>
              <th>Color Value</th>
              <th>Update</th>

            </tr>

            <tr v-for="color in all_colors" v-bind:key="color._id">
              <td
                  v-bind:style="{
            'text-transform':'capitalize',
            color: color.value ? color.value : ''}"

                  style=";">{{ color.value }}</td>
              <td>
                <b-form-input v-model="color.title" :placeholder="color.title"></b-form-input>

              </td>
              <td><a href="#" @click="change_color(color)">Update</a></td>


            </tr>
          </table>

        </div>
        </div>
      </div>



    <b-modal
        ok-variant="danger"
        @ok="freeze_confirm"
        id="modal-category-freeze" >
      <div class="d-block" v-if="selected_user">Are you sure you want to {{selected_user.is_active ? 'Freeze' : 'Unfreeze'}} "{{ selected_user.email }}"?</div>
    </b-modal>
    <b-modal
        ok-variant="danger"
        @ok="admin_confirm"
        id="modal-category-admin" >
      <div class="d-block" v-if="selected_user">Are you sure you want to make "{{ selected_user.email }}" {{selected_user.role === "admin" ? 'a simple user' : ' an admin'}} ?</div>
    </b-modal>

    <b-modal
        ok-variant="danger"
        @ok="reset_confirm"
        id="modal-category-reset" >
      <div class="d-block" v-if="selected_user">Are you sure you want to make to reset password of "{{ selected_user.email }}"?</div>
    </b-modal>

  </div>
</template>

<script>

import helperMixins from "../mixins/helperMixins";
import axios from "axios";
import {variables} from "../constants/systemConstants";
import {mapActions, mapGetters} from "vuex";
import moment from 'moment';
export default {
  name: "Settings",

  mixins : [helperMixins],
  data () {
    return {
      loading: false,
      all_users : [],
      all_colors : [],

      selected_user : undefined,
      name : '',
      email : '',
    }
  },

  beforeMount() {

    if(this.getUser.role!=="admin" || this.getUser.role!=="super_admin"){
      this.getUsers()
      this.getColors()
    }

    else{
      let app = this

      app.$auth.logout({
        params: { token: app.$auth.token() },
        makeRequest: true,
        success: function () {
          app.nullUserObj('')
          location.reload()
        },
        error: function () {
          console.debug('Unable to sing out')
        }
      })
    }


  },

  computed: {
    ...mapGetters(['getUser'])
  },

  mounted() {


  },
  methods: {
    ...mapActions(['nullUserObj']),
    getUsers(){

      let app = this
      axios
          .get("/auth/get_users")
          .then((res) => {

            let all_users = res.data.users

            app.all_users = all_users
            app.loading = false

          })
          .catch(() => {
            app.loading = false
            app.makeToast(
                variables.danger,
                "Unable to load users",
                variables.danger
            );
          });

    },

    getColors(){

      let app = this
      axios
          .get("/auth/get_colors")
          .then((res) => {

            let all_colors = res.data.colors

            app.all_colors = all_colors
            app.loading = false

          })
          .catch(() => {
            app.loading = false
            app.makeToast(
                variables.danger,
                "Unable to load colors",
                variables.danger
            );
          });

    },


    getDate(last_active_time) {
      if (last_active_time) {
      return moment(last_active_time).format("DD-MM-YYYY HH:mm:ss")
    }
      else{
        return "Not active yet"
      }
    },
    toggle_freeze(user) {

      this.selected_user = user
      this.$root.$emit('bv::show::modal', 'modal-category-freeze', '#btnShow')

    },


    freeze_confirm(){

      let app = this
      app.selected_user.is_active = !app.selected_user.is_active

      axios
          .post("/auth/edit_user", app.selected_user)
          .then(() => {
            app.makeToast(variables.success, "Data saved", variables.success);
            app.getUsers()
          })
          .catch(() => {
            app.makeToast(
                variables.danger,
                "Unable to serve this request right now",
                variables.danger
            );
          });

    },


    toggle_make_admin(user) {
      this.selected_user = user
      this.$root.$emit('bv::show::modal', 'modal-category-admin', '#btnShow')
    },



    admin_confirm(){

      let app = this
      app.selected_user.role = app.selected_user.role === "admin" ? 'user' : 'admin'

      axios
          .post("/auth/edit_user", app.selected_user)
          .then(() => {
            app.makeToast(variables.success, "Data saved", variables.success);
            app.getUsers()
          })
          .catch(() => {
            app.makeToast(
                variables.danger,
                "Unable to serve this request right now",
                variables.danger
            );
          });

    },

    create_user(){

      let app = this


      if (this.email === '') {
        app.makeToast(variables.warning, 'Please add the email', app.notification_tag)
        return
      }
 if (this.name === '') {
        app.makeToast(variables.warning, 'Please add the name', app.notification_tag)
        return
      }

      if (!this.validEmail(this.email)) {
        app.makeToast(variables.warning, 'Invalid email', app.notification_tag)
        return
      }

      axios
          .post("/auth/create_user", {name : app.name, email : app.email})
          .then(() => {
            app.makeToast(variables.success, "New user has been created, please follow the email", variables.success);
            app.getUsers()
          })
          .catch((resp) => {

            console.log(resp.response.data)
            app.makeToast(
                variables.danger,
                resp.response.data.message,
                variables.server_error
            );
          });

    },



    reset_password(user) {
      this.selected_user = user
      this.$root.$emit('bv::show::modal', 'modal-category-reset', '#btnShow')

    },


    reset_confirm() {

      let app = this
      axios
          .post("/auth/reset_password", app.selected_user)
          .then(() => {
            app.makeToast(variables.success, "Password reset, please check the email, reset link should be there", variables.success);
            app.getUsers()
          })
          .catch(() => {
            app.makeToast(
                variables.danger,
                "Unable to serve this request right now",
                variables.danger
            );
          });

    },



    change_color(color){

      console.log(color)

      let app = this
      axios
          .post("/auth/edit_color", color)
          .then(() => {
            app.makeToast(variables.success, "Color updated", variables.success);
            app.getColors();
          })
          .catch(e => {
            app.makeToast(
                variables.danger,
                e.response.data.message,
                variables.server_error
            );
          });

    }
  }
}
</script>

<style scoped>

@media only screen and (max-width: 768px) {

  .table {
    width: 800px;
    font-size: 15px;
  }
}
.users {
/*background-color: pink;*/
}
</style>
