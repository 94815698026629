import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'

Vue.config.productionTip = false
import { variables } from "./constants/systemConstants";
Vue.use(require('vue-moment'));

import FadeLoader from 'vue-spinner/src/FadeLoader'
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = variables.defaultURL;
Vue.component('fade-loader', FadeLoader)

window.$ = window.jQuery = require('jquery')


import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft, faChevronRight, faPlusCircle, faTrash, faEdit, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faChevronLeft, faChevronRight, faPlusCircle, faTrash, faEdit, faPrint)


Vue.component('font-awesome-icon', FontAwesomeIcon)

// @ts-ignore
Vue.router = router

Vue.use(require("@websanova/vue-auth"), {
  auth: require("@websanova/vue-auth/drivers/auth/bearer.js"),
  http: require("@websanova/vue-auth/drivers/http/axios.1.x.js"),
  router: require("@websanova/vue-auth/drivers/router/vue-router.2.x.js")
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
