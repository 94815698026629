<template>
  <header class="app-header">
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex ">
          <div class="branding-wrap ">
            <!--brand start-->
            <div class="navbar-brand pos-fixed">


            </div>
            <!--brand end-->
          </div>

          <!--top mega menu start-->
          <nav id="tb-menu">
            <!--start responsive nav toggle button-->
            <div style="position: relative; z-index: 1;" class="nav-btn" @click="toggleSidebar = !toggleSidebar">
<!--              <span class="bars"></span>-->
              <router-link :to="{name : 'Dashboard'}">
                <img style="width: 38px;" src="../assets/img.png" srcset="../assets/img.png" alt="CodeLab">
              </router-link>
            </div>

          </nav>
          <!--top mega menu end-->

          <!--header rightside links-->
          <ul class="ml-auto align-self-center header-links hide-arrow navbar " :class="{'header-links-login'  : !$auth.check()}">


            <li>
              <div class="input-group " style="position: relative">
                <b-form-input list="my-list-id"  type="text" class="form-control input_text_box" placeholder="Search..." v-model="search_" @focusout="reset" v-on:keyup.enter="search"></b-form-input>
                <div class="input-group-append">
                  <span style="cursor:pointer;" class="input-group-text" >
                    <i class="fa fa-search" @click="search"></i>
                  </span>
                </div>

                <div style="position: absolute; top: 50px">
<!--                <select @change="open_event" v-model="selected" v-if="search_result.length > 0">-->
<!--                  <option v-for="search in search_result" v-bind:key="search.raw._id" :value="JSON.stringify(search.raw)">{{ search.refined }}</option>-->
<!--                </select>-->
                  <ul class="search_list" v-if="search_result.length > 0">
                    <li v-for="(search, index) in search_result" v-bind:key="search.raw._id" class="search_list_elem"
                        :style="{
                      'padding' : '5px',
                        'display':'block',
                        'background-color' :  index % 2 === 0 ? '#eee' : '#fff'}
"

                        @click="open_event(search.raw)">
                      <p>{{ search.refined }}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </li>



            <template  v-if="$auth.check()">
              <li class="nav-item dropdown ">
                <a class="nav-link dropdown-toggle" id="userNav" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <div class="user-thumb">
                    <img class="rounded-circle" src="/img/avatar/avatar2.jpg" alt=""/>
                  </div>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userNav">
                  <div class="dropdown-item- px-3 py-2">
                    <img class="rounded-circle mr-2" src="/img/avatar/avatar2.jpg" width="35" alt=""/>
                    <span class="text-muted">{{getUser.name}}</span>
                  </div>
                  <div class="dropdown-divider"></div>
                  <!--<a class="dropdown-item" href="#">My Profile</a>-->
                  <router-link to="/settings" v-if="getUser.role === 'admin' ||  getUser.role === 'super_admin'"> <a class="dropdown-item" href="#">  Admin </a> </router-link>
                  <!--<a class="dropdown-item" href="#">Inbox <span class="badge badge-primary">3</span></a>-->
                  <!--<a class="dropdown-item" href="#">Message <span class="badge badge-success">5</span></a>-->
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#" @click="log_out">Sign Out</a>
                </div>
              </li>
            </template>

          </ul>

        </div>
      </div>
    </div>
  </header>
</template>

<script>

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
import moment from 'moment'

import { mapGetters, mapActions } from 'vuex'
import axios from "axios";
// import {variables} from "../constants/systemConstants";

export default {
  name: 'TopNav',

  watch: {
    search_: function () {
      this.search()
    },
  },
  data () {
    return {
      toggleSidebar: false,
      search_ : '',
      selected : {},
      search_result : []
    }
  },
  created () {
    console.log(moment())
  },
  computed: {
    ...mapGetters(['getUser'])
  },
  methods:
      {
        ...mapActions(['nullUserObj', 'changeEvent']),

        reset(){
          let app = this
          setTimeout(()=> {
            app.search_result = []
          }, 1000)

        },
        search(){

          // console.log("I am changed")

          if(this.search_ === "")
          {
            this.search_result = []
            return
          }
          let data = {
            search : this.search_
          }

          let app = this
          axios
              .post("/auth/search", data, {  cancelToken: source.token})
              .then((res) => {

                let data = []
                for(let i = 0; i <res.data.events.length; i++ )
                {
                  let event = res.data.events[i]
                  let new_obj =  event.time
                  + " " +  event.customer_name
                      // + " " + event.description
                      // + " " + event.customer_email
                      // + " " + event.color
                      // + " " + event.attachment + " " + event.date_ + " " + event.deposit

                  data.push({refined : new_obj, raw : res.data.events[i]})
                }
                app.search_result = data
              })
              .catch(e => {
                    if (axios.isCancel(e)) {
                      console.debug('Request canceled');
                    } else {
                      console.debug('Request canceled');

                      // app.makeToast(
                      //     variables.danger,
                      //     e.response.data.message,
                      //     variables.server_error
                      // );
                    }
              });
        },

        open_event(obj){
          // console.log(obj, "changed")
          this.changeEvent(obj)
        },
        saveSelectionAndReset()
        {
          // console.log(this.search_result.length)
        },
        log_out () {
          let app = this

          app.$auth.logout({
            params: { token: app.$auth.token() },
            makeRequest: true,
            success: function () {
              app.nullUserObj('')
              location.reload()
            },
            error: function () {
              console.debug('Unable to sing out')
            }
          })
        },
      }
}
</script>

<style scoped>
/*.input_text_box:focus{*/
/*  width: 700px;*/
/*}*/

.input_text_box {
  width: 226px
}

.search_list{
  border: 1px solid #333;
  display: block !important;
  background-color: white;
  list-style: none;
  width: 700px;
}


.search_list_elem:hover{

  background-color: grey;
  cursor: pointer;
}



@media only screen and (max-width: 768px) {

  .input_text_box:focus{
    width: 226px;
  }

  .search_list{
    display: block !important;
    background-color: white;
    list-style: none;
    width: 226px;
  }


  .input_text_box {
    width: 226px
  }


}

/* Portrait */
@media only screen
and (min-width: 1024px)
and (max-height: 1366px)
and (orientation: portrait)
and (-webkit-min-device-pixel-ratio: 1.5) {
  .input_text_box:focus{
    width: 226px;
  }

  .input_text_box {
    width: 226px
  }

  .search_list{
    display: block !important;
    background-color: white;
    list-style: none;
    width: 226px;
  }


}

</style>
