<template>

  <div class="calender">

    <div class="left_30">

      <div class="left_table_container">

        <div class="date_and_year">
          {{moment.format('MMMM YYYY')}}

          <div class="icons_space">
          <font-awesome-icon :icon="['fas', 'chevron-left']"  style="margin-right: 20px; cursor: pointer" @click="change_month(false)" />
          <font-awesome-icon :icon="['fas', 'chevron-right']" style="cursor: pointer" @click="change_month(true)"/>
          </div>
        </div>

      <table class="left_short">

        <tr>
          <th style="border-left: none">
            Sun
          </th>
          <th>
            Mon
          </th>
          <th>
            Tue
          </th>
          <th>
            Wed
          </th>
          <th>
            Thu
          </th>
          <th>
            Fri
          </th>
          <th>
            Sat
          </th>
        </tr>

        <tr v-for="(week, index) in weeks" v-bind:key="index+ '__'">
          <td v-for="(day,index_2) in week" v-bind:key="day.date + '_' + index_2"  v-bind:style="{
            'border-left': index_2 === 0 ? 'none' : '',
            color: moment.isSame(day.date, 'month') ? 'black' : '',

          }">
            <span :class="moment_current.isSame(day.date, 'day') ? 'blue_background' : ''"> {{day.date.format('DD')}} </span>
          </td>
        </tr>





      </table>


      </div>

      <div class="left_table_container">
        <b-form-group label="Filter bookings by color:" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="color_filter"
              :aria-describedby="ariaDescribedby"
              name="flavour-2"
              @change="init"
          >
            <b-form-checkbox v-for="color in all_colors" :value="color.value"
                             v-bind:style="{
            'text-transform':'capitalize',
            color: color.value ? color.value : ''}"

                             v-bind:key="color._id">{{ color.title }}</b-form-checkbox>

          </b-form-checkbox-group>
        </b-form-group>

        <p>Live bookings: <strong>{{total_booking_numbers}}</strong></p>

      </div>

    </div>
    <div class="right_70">


      <div class="date_and_year_mobile">


          <font-awesome-icon :icon="['fas', 'chevron-left']"  style="margin-right: 20px; cursor: pointer" @click="change_month(false)" />

          <p>{{moment.format('MMMM YYYY')}}</p>

          <font-awesome-icon :icon="['fas', 'chevron-right']" style="margin-left: 20px; cursor: pointer" @click="change_month(true)"/>

      </div>


      <div class="mobile_color_picker">
        <b-form-group label="Filter bookings by color:" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="color_filter"
              :aria-describedby="ariaDescribedby"
              name="flavour-2"
              @change="init"
          >
            <b-form-checkbox v-for="color in all_colors" :value="color.value"
                             v-bind:style="{
            'text-transform':'capitalize',
            color: color.value ? color.value : ''}"

                             v-bind:key="color._id">{{ color.title }}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

        <p>Live bookings: <strong>{{total_booking_numbers}}</strong></p>
      </div>

      <table class="main_table">

        <tr>
          <th style="border-left: none">
            Sun
          </th>
          <th>
            Mon
          </th>
          <th>
            Tue
          </th>
          <th>
            Wed
          </th>
          <th>
            Thu
          </th>
          <th>
            Fri
          </th>
          <th>
            Sat
          </th>
        </tr>

        <tr v-for="(week, index) in weeks" v-bind:key="index+ '__'">
          <td v-for="(day,index_2) in week" v-bind:key="day.date + '_' + index_2"  v-bind:style="{
            cursor : 'pointer',
            'border-left': index_2 === 0 ? 'none' : '',
            color: moment.isSame(day.date, 'month') ? 'black' : '',
            backgroundColor: day.events.length > 0 ? '' : ''
            }"
              @click="show_all_events(day)"
          >
            <div class="div_td">
              <div class="top_center">
                <span :class="moment_current.isSame(day.date, 'day') ? 'blue_background' : ''"> {{day.date.format('DD')}} </span>
              </div>
              <div class="bottom_right" v-if="day.events.length > 0">
<!--                <div>-->
<!--                  <span class="blue_background" style="width: 30px; display: inline-block;"> {{day.events.length }} </span>-->
<!--                </div>-->
<!--                -->
                <div v-for="(day_, index) in day.events" v-bind:key="day_._id">
                  <div v-if="index < 2"
                       v-bind:style="{
                    'color' : 'white',
                    'font-size' : '12px',
                    'text-align' : 'start',
                    'overflow': 'hidden',
                    'height': '20px',
                    'backgroundColor': day_.color !== 'false' && day_.color !== '' ? day_.color : 'grey'

                  }"
                  >
                    {{day_.time}} {{day_.customer_name}}
                  </div>
                </div>

                <div v-if="day.events.length > 2">
                  <div>+{{day.events.length - 2}} more</div>
                </div>
              </div>
            </div>
          </td>
        </tr>





      </table>
    </div>

    <b-modal id="modal-center" centered title="Events">

      <div class="events_container">

      <div class="event_container" v-for="(event, index) in events" v-bind:key="index + 'event'" >

        <font-awesome-icon v-if="getUser.role==='admin' || getUser.role==='super_admin'" :icon="['fa', 'trash']"  style="cursor: pointer; font-size: 20px; color: #f1536e; margin-left: 5px" @click="delete_event(event)"/>

        <p style="width: 70%">{{getDate(event.date) + " " + event.time}} {{event.customer_name}} </p>

        <div style="display: flex">
          <font-awesome-icon :icon="['fa', 'edit']"  style="cursor: pointer; font-size: 20px; color: #3da5f4; " @click="open_editing_modal(event)"/>
        </div>

      </div>


        <div class="add_new">
          <font-awesome-icon :icon="['fa', 'print']"  style="cursor: pointer; font-size: 40px; color: #f1536e" @click="open_print_modal(null)"/>

          <font-awesome-icon :icon="['fa', 'plus-circle']"  style="cursor: pointer; font-size: 40px; color: #3da5f4" @click="open_editing_modal(null)"/>
        </div>

      </div>

    </b-modal>


    <b-modal id="modal-center-event"
             no-close-on-backdrop
             hide-footer
             centered title="New event">

      <div class="events_container_">

        <div>
          <b-form-input v-model="event.customer_name" placeholder="Customer Name"></b-form-input>

<!--          <b-form-input v-model="event.title" placeholder="Title"></b-form-input>-->
        </div>

  <div>
          <b-form-input v-model="event.customer_number" placeholder="Customer Number"></b-form-input>

<!--          <b-form-input v-model="event.title" placeholder="Title"></b-form-input>-->
        </div>
        <div>
          <b-form-input v-model="event.number_of_people" placeholder="Number of people" type="number"></b-form-input>

<!--          <b-form-input v-model="event.title" placeholder="Title"></b-form-input>-->
        </div>


        <div class="date_time">
          <b-form-input  :value="getDate(event.date)" placeholder="Date" disabled></b-form-input>
          <b-form-select v-model="event.time" :options="options"></b-form-select>
        </div>



        <div>
          <b-form-input v-model="event.customer_email" placeholder="Email address"></b-form-input>

        </div>

        <div>

          <b-form-textarea
              id="textarea"
              v-model="event.description"
              placeholder="Description"
              rows="10"
              max-rows="10"
          ></b-form-textarea>

        </div>


<!--        <div>-->
<!--          <b-form-input v-model="event.deposit" placeholder="Deposit"></b-form-input>-->

<!--        </div>-->


        <div>
          <b-form-file
              v-model="file"
              :state="Boolean(file)"
              ref="file1"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-on:change="handleFileUpload()"

          ></b-form-file>


          <a href="#" @click="download_file(event.attachment)" v-if="event.attachment"> Download file: {{event.attachment}}</a>
        </div>


        <div>
          <b-form-checkbox
              id="checkbox-1"
              v-model="event.send_email"
              name="checkbox-1"
              value="true"
              unchecked-value="false"
          >
            Send email to customer
          </b-form-checkbox>
        </div>


        <div>
          <b-form-group label="Assign a color:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                id="checkbox-group-2"
                v-model="event.color"
                :aria-describedby="ariaDescribedby"
                name="flavour-2"
            >
              <b-form-checkbox v-for="color in all_colors" :value="color.value"
                               v-bind:style="{
            'text-transform':'capitalize',
            color: color.value ? color.value : ''}"

                               v-bind:key="color._id">{{ color.title }}</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>

        </div>

        <b-button type="submit" variant="primary" @click="create_event">Submit</b-button>


        <div v-if="event.all_emails">

          <h3>Emails sent {{event.all_emails.length === 0 ? 'No emails sent' : ''}}</h3>


          <div v-for="email_ in event.all_emails" v-bind:key="email_._id">
          <a href="#"   @click="show_email(email_)"> To: {{email_.to}}, sent at:  {{getTime(email_.time)}}</a>
          </div>

        </div>

      </div>

    </b-modal>


    <b-modal
        ok-variant="danger"
        @ok="delete_category_confirmed"
        id="modal-category" >
      <div class="d-block" v-if="event_to_del">Are you sure you want to delete "{{ event_to_del.customer_name }}"?</div>
    </b-modal>

    <b-modal
        ok-variant="info"
        id="modal-center-email" >
      <div class="d-block" v-if="email">

        <p>Title : {{email.title}}</p>
        <p>Email : {{email.to}}</p>
        <p>Date : {{getTime(email.time)}}</p>
        <p>Deposit : {{email.deposit}}</p>


      </div>
    </b-modal>


    <div class="on_top" v-if="loading">
    <div v-if="loading" >
      <fade-loader color="#506dfe" size="19px" style="text-align: center"/>
    </div></div>


<!--    <div id="print" style="display: none">-->
<!--   -->
    <div id="print" style="display: none" v-if="events.length > 0">

      <h3 style="text-align: center;margin-top: 50px"> Date: {{ getDate(events[0].date) + " " + getDay(events[0].date) }}</h3>

      <div v-for="event in events" v-bind:key="event._id + '__' " style="margin: 50px">

        <div style="display: flex; flex-direction: row; justify-content: space-between">
        <p>Customer Name : {{event.customer_name}}</p>
        <p>Number of people : {{event.number_of_people}}</p>
        <p></p>
        </div>
        <p>Time : {{event.time}}</p>

        <p v-if="event.customer_number !== '' ">Customer Phone Number : {{event.customer_number}}</p>
        <p v-if="event.customer_email !== '' ">Customer Email : {{event.customer_email}}</p>
        <p v-if="event.description !== '' ">Description : {{event.description}}</p>
        <br>
        <br>
        <p style="margin: 0px auto"> ---------------------------------- </p>

      </div>

    </div>

  </div>
</template>

<script>
import moment from 'moment'
import {variables} from "../constants/systemConstants";
import axios from "axios";
import helperMixins from "../mixins/helperMixins";
import {mapGetters} from "vuex";
export default {
  name: "Calender",

  watch: {
    getCart: function () {
      this.open_editing_modal(this.getCart)
      // console.log(this.getCart)
    },
  },
  mixins : [helperMixins],
  data () {
    return {
      month_index: 0,
      first_day: null,
      last_day: null,
      last_day_previous_month: null,
      last_day_current_month: null,
      number_of_weeks: null,
      moment: moment(),
      moment_current: moment(),
      date_format : 'YYYY-MM-DD',
      weeks: [],
      selected_date: null,
      total_booking_numbers: '~',
      event : {
        title: '',
        date: '',
        time:  '17:00',
        customer_name: '',
        customer_number: '',
        customer_email: '',
        description: '',
        deposit: 0.00,
        number_of_people: 1,
        attachment: '',
        color : '',
        send_email : false
      },
      email : null,

      // event : {
      //   title: 'James Birthday',
      //   date: moment().format('YYYY-MM-DD'),
      //   customer_name: 'James Xia',
      //   customer_email: 'xia@email.com',
      //   description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.' ,
      //   deposit: '200.0',
      //   attachment: '',
      //   color : '',
      //   send_email : true
      // },
      file : undefined,
      // events: [
      //   {
      //     title: "Hassan's birthday Lorem ipsm dollar sit, Lorem ipsm dollar sit, "
      //   },
      //   {
      //     title: "Hassan's birthday"
      //   },
      // ],

      events: [],
      all_colors: [],
      color_filter: '',


      event_to_del : undefined,

      loading : false,

      options: []

    }
  },

  beforeMount() {


    let now = moment().startOf('day')

    for(let i = 0; i < 48; i++)
    {
      let obj = {
        value: now.clone().format("HH:mm"),
        text: now.clone().format("HH:mm")
      }

      this.options.push(obj)

      now = now.add("30", "minutes")
    }

    this.init()
    this.getColors()
  },

  mounted() {
    // this.$root.$emit('bv::show::modal', 'modal-center', '#btnShow')

  },
  computed: {
    ...mapGetters(['getUser', 'getCart'])
  },

  methods: {

    init(){

      this.loading = true


      const current_date = moment().add(this.month_index, 'months')

      this.moment = current_date

      const dateFrom = current_date.clone().subtract(1, 'months').endOf('month').startOf('week');
      const dateTo = current_date.clone().add(1, 'months').startOf('month').endOf('week');
      const last_day_previous_month = current_date.clone().subtract(1, 'months').endOf('month');
      const last_day_current_month = current_date.clone().endOf('month');

      this.number_of_weeks = Math.abs(dateFrom.diff(dateTo, 'weeks')) + 1

      this.first_day = dateFrom.clone()
      this.last_day = dateTo.clone()
      this.last_day_previous_month = last_day_previous_month.format('DD')
      this.last_day_current_month = last_day_current_month.format('DD')


      let weeks = []
      for (let i = 0; i < this.number_of_weeks; i++) {
        let week = []
        for (let k = 0; k < 7; k++) {

          let date_object = {date : dateFrom.clone(), events : []}
          dateFrom.add(1, 'days')

          week.push(date_object)
        }
        weeks.push(week)
      }

      this.weeks = weeks

      this.getEvents()
    },

    getEvents(){



      let app = this;

      let url = "/auth/get_events";

      axios
          .post(url, {
            "from" : this.first_day.format(app.date_format),
            "to" : this.last_day.format(app.date_format),
            color_filter : this.color_filter
          })
          .then((res) => {
            let events = res.data.events
            let total_booking_numbers = res.data.all_events_this_year
            app.total_booking_numbers = total_booking_numbers
            if(events) {
              for (let h = 0; h < events.length; h++) {
                for (let i = 0; i < app.weeks.length; i++) {
                  for (let k = 0; k < app.weeks[i].length; k++) {
                    if (app.weeks[i][k].date.isSame(moment(events[h].date), 'day')) {
                      app.weeks[i][k].events.push(events[h])
                      // console.log(app.weeks[i][k])
                    }
                  }
                }
              }

              for (let i = 0; i < app.weeks.length; i++) {
                for (let k = 0; k < app.weeks[i].length; k++) {
                  let events = JSON.parse(JSON.stringify(app.weeks[i][k].events))
                  events.sort(function(a, b){
                    return moment(app.getDate(a.date) + " " + a.time, "DD-MM-YYYY HH:mm").unix() - moment(app.getDate(b.date) + " " + b.time, "DD-MM-YYYY HH:mm").unix()
                  });

                  app.weeks[i][k].events = events
                }
              }

            }



            app.loading = false

          })
          .catch(() => {
            app.loading = false
            app.makeToast(
                variables.danger,
                "Unable to load events",
                variables.danger
            );
          });


    },

    change_month(is_increment){
      if(is_increment){
        this.month_index = this.month_index + 1
      }else{
        this.month_index = this.month_index - 1
      }

      this.init()
    },
    show_all_events(date){

      let app = this
      let events  = JSON.parse(JSON.stringify(date.events))
      events.sort(function(a, b){
        return moment(app.getDate(a.date) + " " + a.time, "DD-MM-YYYY HH:mm").unix() - moment(app.getDate(b.date) + " " + b.time, "DD-MM-YYYY HH:mm").unix()
      });

      // console.log(JSON.stringify(events))
      this.events = events
      this.selected_date = date.date.clone().format(this.date_format)

      this.$root.$emit('bv::show::modal', 'modal-center', '#btnShow')

    },

    getColors(){

      let app = this
      axios
          .get("/auth/get_colors")
          .then((res) => {

            let all_colors = res.data.colors

            app.all_colors = all_colors
            app.loading = false

          })
          .catch(() => {
            app.loading = false
            app.makeToast(
                variables.danger,
                "Unable to load colors",
                variables.danger
            );
          });

    },



    show_email(email){

      this.email = email

      this.$root.$emit('bv::show::modal', 'modal-center-email', '#btnShow')

    },

    hide_both_modals(){
      this.$root.$emit('bv::hide::modal', 'modal-center-event', '#btnShow')
      this.$root.$emit('bv::hide::modal', 'modal-center', '#btnShow')

    },

    open_editing_modal(event){

      if(event === null)
      {
        this.event = {
          title: '',
          date: this.selected_date,
          time: '17:00',
          customer_name: '',
          customer_number: '',
          customer_email: '',
          description: '',
          deposit: 0.00,
          number_of_people: 1,
          attachment: '',
          color : '',
          send_email : false
        }


        // this.event = {
        //   title: 'James Birthday',
        //   date: moment().format('YYYY-MM-DD'),
        //   customer_name: 'James Xia',
        //   customer_email: 'hassanalvi11@gmail.com',
        //   description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.' ,
        //   deposit: '200.0',
        //   attachment: '',
        //   color : '',
        //   send_email : "true"
        // }


      }
      else {
        this.event = JSON.parse(JSON.stringify(event))
        this.event.send_email = false
      }
      this.$root.$emit('bv::show::modal', 'modal-center-event', '#btnShow')
    },

    open_print_modal(){

      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

// Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

// Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    },
    handleFileUpload () {

      let app = this
      setTimeout(()=> {
        app.submitFile()
      }, 200)

    },


    submitFile() {

      /*
              Initialize the form data
          */
      let formData = new FormData();

      /*
          Add the form data we need to submit
      */
      formData.append("file", this.file);

      /*
        Make the request to the POST /single-file URL
      */

      let app = this;
      app.makeToast(
          variables.warning,
          "About to upload file",
          variables.success
      );

      axios
          .post("/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(function(res) {
              app.event.attachment = res.data.file;
              app.makeToast(variables.success, "File uploaded", variables.success);
          })
          .catch(function() {
            app.makeToast(variables.danger, "Unable to upload file", variables.danger);
          });
    },

    create_event(){

      let app = this



      // if(!app.event.title || app.event.title === '')
      // {
      //   app.makeToast(
      //       variables.warning,
      //       "Please add title",
      //       variables.server_error
      //   );
      //   return
      // }

      if(!app.event.date || app.event.date === '')
      {
        app.makeToast(
            variables.warning,
            "Please try again",
            variables.server_error
        );
        return
      }

      if(!app.event.customer_name || app.event.customer_name === '')
      {
        app.makeToast(
            variables.warning,
            "Please add customer name",
            variables.server_error
        );
        return
      }



      if(!app.event.customer_email || app.event.customer_email === '')
      {
        if(app.event.send_email)
        {
          app.makeToast(
              variables.warning,
              "Please check customer email address",
              variables.server_error
          );
          return
        }
      }


      if(app.event.customer_email !== '' && !app.validEmail(app.event.customer_email))
      {
        app.makeToast(
            variables.warning,
            "Please check customer email address",
            variables.server_error
        );
        return
      }

      if(app.event.deposit === null || app.event.deposit === '')
      {
        app.makeToast(
            variables.warning,
            "Please confirm the deposit",
            variables.server_error
        );
        return
      }


      if(app.event._id)
      {

        app.event.event_id = app.event._id


        axios
            .post("/auth/edit_event", app.event)
            .then(() => {
              app.makeToast(variables.success, "Booking updated", variables.success);
              app.init();
              app.hide_both_modals()
            })
            .catch(e => {
              app.makeToast(
                  variables.danger,
                  e.response.data.message,
                  variables.server_error
              );
            });
      }
      else {
        axios
            .post("/auth/create_event", app.event)
            .then(() => {
              app.makeToast(variables.success, "Booking has been created", variables.success);
              app.init()
              app.hide_both_modals()


            })
            .catch(e => {
              app.makeToast(
                  variables.danger,
                  e.response.data.message,
                  variables.server_error
              );
            });
      }

    },
    delete_event(event){

      this.event_to_del = event
      this.$root.$emit('bv::show::modal', 'modal-category', '#btnShow')

    },
    delete_category_confirmed(){

      // console.log(JSON.stringify(this.event_to_del))

      let data = {
        event_id : this.event_to_del._id
      }

      let app = this
      axios
          .post("/auth/delete_event", data)
          .then(() => {
            app.makeToast(variables.success, "Booking has been deleted", variables.success);
            app.init()
            app.hide_both_modals()

          })
          .catch(e => {
            app.makeToast(
                variables.danger,
                e.response.data.message,
                variables.server_error
            );
          });

    },
    download_file(file_name){

      let url = variables.defaultURL + '/attachments/' + file_name
      // console.log(variables.defaultURL + '/attachments/' + file_name)

      window.open(url, '_blank').focus();

      // axios({event_container
      //   url: '/attachments/' + file_name, //your url
      //   method: 'GET',
      //   responseType: 'blob', // important
      // }).then((response) => {
      //   const url = window.URL.createObjectURL(new Blob([response.data]));
      //   const link = document.createElement('a');
      //   link.href = url;
      //   // link.setAttribute('download', file_name); //or any other extension
      //   document.body.appendChild(link);
      //   link.click();
      // });

    },
    getTime(time) {
      return moment.unix(time).format("DD-MM-YYYY hh:mm:ss")
    },
    getDate(time)
    {
      return moment(time).format("DD-MM-YYYY")
    },

    getDay(time)
    {
      return moment(time).format("dddd")
    }
  }
}
</script>

<style scoped>

.calender {
  display: flex;
  flex: 1;
  flex-direction: row;
  border-top: 1px solid #dadce0;
}

.left_30 {
  width: 20%;

}



.right_70 {
  width: 80%;
  height: 100vh;
  border-left: 1px solid #dadce0;
}

table {

  width: 100%;
  height: 100%;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}

tr {

  height: 10%;
  width: 100%;
  overflow: scroll;
}
 th, td {

   width: 14%;
  border: 1px solid #dadce0;
  border-top: none;
   vertical-align: text-top;
   text-align: center;
   color: #70757a;

}

 .blue_background {
   background: #1a73e8;
   color: white;
   border-radius: 20px;
   padding: 3px;
   width: 30px;
 }


.left_table_container{
  font-size: 12px;
  width: 80%;
  margin: 0 auto;
  margin-top: 10vh;

}

 .left_short {

   height: 40%;
   border: none;
 }

 table.left_short th {
   border: none;
 }

 table.left_short td {
   border: none;
 }

 .date_and_year {
   font-weight: bold;
   font-size: 16px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
 }

 .icons_space {
   margin-left: 10px;
 }

 .date_time {
   display: flex;
   flex-direction: row;
   gap: 20px;
 }


 .events_container{
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
 }

.events_container_{
  gap: 10px;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
 }

 .event_container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   border: 1px solid #70757a;
   border-radius: 10px;
   padding: 5px;
   margin: 10px;
 }

 .add_new {
   margin-top: 20px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-right: 10px;
 }

.div_td {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  align-items: center;
}
 .bottom_right {
   width: 100%;
   height: 70%;
 }

.top_center {
   width: 100%;
   height: 30%;
 }


input[type=text] {
  /*border: 1px solid black ;*/
}

.date_and_year_mobile{
  display: none;
  visibility: hidden;
}

.mobile_color_picker{
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 768px) {

  .left_30 {
    display: none;
    visibility: hidden;
    width: 0%;
  }

  .right_70{
    width: 100%;
  }


  .date_and_year_mobile{
    visibility: visible;
    font-weight: bold;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 10%;
    align-items: center;
  }

  .div_td {
    height: 100px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    align-items: center;
  }

  .main_table{
    border: 1px solid #ddd;
    /*height: 60%;*/
  }

  .main_table tr {
    border: 1px solid #ddd;
    /*height: 10px;*/
    min-height: 120px;

  }
  .main_table th {
    border: 1px solid #ddd;
    height: 1%;

  }

  .main_table td {
    border: 1px solid #ddd;
    min-height: 120px;
    /*height: 1%;*/

  }


  .mobile_color_picker{
    display: block;
    visibility: visible;
  }

}


@media only screen
and (min-device-width: 768px)
and (max-device-width: 1260px)
and (-webkit-min-device-pixel-ratio: 1) {
  .left_30 {
    display: none;
    visibility: hidden;
    width: 0%;
  }

  .right_70{
    width: 100%;
  }


  .date_and_year_mobile{
    visibility: visible;
    font-weight: bold;
    font-size: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 10%;
    align-items: center;
  }

  .div_td {
    height: 100px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    align-items: center;
  }

  .main_table{
    border: 1px solid #ddd;
    /*height: 60%;*/
  }

  .main_table tr {
    border: 1px solid #ddd;
    /*height: 10px;*/
    min-height: 120px;

  }
  .main_table th {
    border: 1px solid #ddd;
    height: 1%;

  }

  .main_table td {
    border: 1px solid #ddd;
    min-height: 120px;
    /*height: 1%;*/

  }


  .mobile_color_picker{
    display: block;
    visibility: visible;
  }
}

/*!* Portrait *!*/
/*@media only screen*/
/*and (min-width: 1024px)*/
/*and (max-height: 1366px)*/
/*and (orientation: portrait)*/
/*and (-webkit-min-device-pixel-ratio: 1.5) {*/


/*}*/
</style>
