<template>


  <div class="signin-up-bg">

    <div class="position-relative">
      <!--login form-->
      <div class="login-form">
        <h2 class="text-center mb-4">
          <img class="img_short" src="../../assets/img.png" srcset="../../assets/img.png" alt="CodeLab">
        </h2>
        <h4 class="text-uppercase- text-purple text-center mb-5">Recover account</h4>
<!--        <form action="index.html">-->

          <div class="form-group">
            <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Please enter your email" v-model="email" disabled>
          </div>

          <div class="form-group mb-4">
              <input type="password" class="form-control" id="exampleInputPassword1" placeholder="New Password" v-model="password">
          </div>

          <div class="form-group mb-4">
              <input type="password" class="form-control" id="exampleInputPassword1_confirm" placeholder="New Password confirm" v-model="password_confirm">
          </div>

          <div class="form-group clearfix">
            <button type="submit" class="btn btn-purple btn-pill " @click="login_button_click">Set Password</button>
          </div>


      </div>
    </div>
  </div>

</template>

<script>

import { mapActions } from 'vuex'
import { variables } from '../../constants/systemConstants'
import helpermixins from '../../mixins/helperMixins'
import axios from 'axios'

export default {
  components: {

  },
  data () {
    return {

      email: null,
      password: '',
      email_sent: false,
      code: null,
      password_confirm: '',
      //   email: 'hassanalvi@gmail.com',
      // password: 'alvi@12345',
      notification_tag: 'Login'
    }
  },

  mounted () {
    this.code = this.$route.query.code
    this.email = this.$route.query.email

    if (!this.code || !this.email) {
      this.$router.push('/')
    }
  },
  mixins: [helpermixins],

  created () {
  },
  methods: {
    ...mapActions(['changeUser']),
    login_button_click () {
      let app = this

      if (this.email === '') {
        app.makeToast(variables.warning, 'Please add your email', app.notification_tag)
        return
      }

      if (this.password === '') {
        app.makeToast(variables.warning, 'Password secures you, We don\'t save your password', 'Registration error')
        return
      }

      if (this.password_confirm === '') {
        app.makeToast(variables.warning, 'Please add your password in confirm password field', 'Registration error')
        return
      }

      if (!this.checkPassword(this.password)) {
        app.makeToast(variables.warning, 'A strong password should be of length 7, it should have a digit, an upper case and one lower case letter' +
                '', 'Registration error')
        return
      }

      if (this.password !== this.password_confirm) {
        app.makeToast(variables.warning, 'Password doesn\'t match', 'Registration error')
        return
      }

      if (!app.email_sent) {
        app.email_sent = true
        app.makeToast(variables.success, 'Please wait', 'Imperial China')
        // send_password_recovery_email
        axios.get('/auth/check_password_verification_code?email=' + this.email + '&code=' + this.code + '&password=' + this.password).then(() => {
          app.makeToast(variables.success, 'Password has been changed, Please login now', 'Imperial China')

          setTimeout(() => {
            app.$router.push('/login')
          }, 4000)
        }
        ).catch(() => {
          app.email_sent = false
          app.makeToast(variables.danger, 'Unable to change your password, Please contact administrator', 'Imperial China')
        }
        )
      } else {
        app.makeToast(variables.warning, 'Please wait we are working ', 'Imperial China')
      }
    }

  },
  watch: {
  }
}
</script>
